<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />

    <div class="flex xs12">
      <va-card :title="$t('languages.index')">
        <remote-table
          crud-links="languages"
          :columns="tableFields"
          :data="tableData"
          :loading="loading"
          :pagination="pagination"
          @update-data="updateData"
          @search-data="searchByName"
          @delete-item="tryDelete"
        >
          <template v-slot:top>
            <div class="flex xs12 md2">
              <va-button
                flat
                small
                color="primary"
                icon="fa fa-language"
                v-if="currentUser.can('Languages', 'update')"
                @click="openTranslator"
              >
                {{ $t('tables.actions.new_translation') }}
              </va-button>
            </div>
            <div class="flex xs12 md2">
              <va-button
                flat
                small
                color="primary"
                icon="fa fa-plus"
                v-if="currentUser.can('Languages', 'add')"
                :to="{name: 'languagesNew'}"
              >
                {{ $t('tables.actions.new_language') }}
              </va-button>
            </div>
          </template>
          <template v-slot:row-preactions="{ props }">
            <va-popover
              :message="$t('tables.actions.download')"
              placement="left"
            >
              <va-button
                flat
                small
                color="success"
                icon="fa fa-download"
                @click.prevent="downloadFile(props.rowData)"
              />
            </va-popover>
          </template>
        </remote-table>
      </va-card>

      <va-modal
        ref="translatorModal"
        v-model="translatorModal"
        :max-width="'100vw'"
        :title="$t('layout.modals.translations')"
        :hide-default-actions="true"
        :fullscreen="true"
        @cancel="closeTranslatorModal"
      >
        <translator-modal />
      </va-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')
const TranslatorModal = () => import(/* webpackPrefetch: true */ '@/components/extras/TranslatorModal')

export default {
  name: 'languages-index',
  components: {
    RemoteTable,
    TranslatorModal,
  },
  data () {
    return {
      translatorModal: false,
      error: false,
      tableData: [],
      pagination: {},
      searchQuery: '',
      loading: false,
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: 'name',
          type: 'asc',
        },

        page: 1,
        perPage: 50,
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    tableFields () {
      return [
        {
          name: 'name',
          title: this.$t('tables.headings.name'),
          sortField: 'name',
          callback: this.nameLabel,
        },
        {
          name: 'type',
          title: this.$t('tables.headings.type'),
          sortField: 'type',
          callback: this.typeLabel,
        },
        {
          name: '__slot:actions',
          hidden: this.tableData.length === 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    openTranslator () {
      this.translatorModal = true
    },
    closeTranslatorModal () {
      this.translatorModal = false
    },
    nameLabel (name) {
      return this.$t(`language.${name}`)
    },
    typeLabel (type) {
      const label = type === 0 ? 'languages.types.web' : 'languages.types.app'
      return this.$t(label)
    },
    fixData (data) {
      return data
    },
    apiUrl (params) {
      let route = 'languages'
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        const field = params.sort.field
        route += '&sort=' + field
        route += '&direction=' + params.sort.type
      }
      if (params.search) {
        route += '&q=' + params.search
      }

      return route
    },
    async updateData (params) {
      params = params || this.serverParams
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async searchByName (name) {
      if (this.serverParams.search !== name) {
        this.serverParams.page = 1
      }
      this.serverParams.search = name
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(this.serverParams))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }
      if (this.serverParams.search !== name) {
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete('languages/' + item.id)
      } catch (err) {
        // console.log('Error deleting region', err)
        this.loading = false
        return
      }
      this.updateData()
    },
    async downloadFile (exportObj) {
      const fileName = exportObj.name
      let dataStr = ''

      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          const obj = JSON.parse(exportObj.translation)
          try {
            dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(obj, undefined, 4))
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()

          const downloadAnchorNode = document.createElement('a')
          downloadAnchorNode.setAttribute('href', dataStr)
          downloadAnchorNode.setAttribute('download', fileName + '.json')
          document.body.appendChild(downloadAnchorNode)
          downloadAnchorNode.click()
          downloadAnchorNode.remove()

          this.$swal.close()
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
